import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.css']
})
export class NavigationBarComponent implements OnInit {

  public MFS_URL = environment.mfs_front_url;

  constructor() { }

  ngOnInit() {
  }

  redirectToLogin() {
    window.open(this.MFS_URL, '_self');
  }

}
