import { Component, OnInit } from '@angular/core';
import $ from "jquery";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  public MFS_URL = environment.mfs_front_url;

  constructor() { }

  ngOnInit() {
  }

  redirectToLogin() {
    window.open(this.MFS_URL, '_self');
  }

}


