import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-download1',
  templateUrl: './download1.component.html',
  styleUrls: ['./download1.component.css']
})
export class Download1Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
